import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  InputAdornment,
  CircularProgress, 
  Box,
  Stack,
  Alert,
  Typography
} from '@mui/material';

import axiosInstance from '../../../axiosConfig';
import useAPI from '../../../hooks/useAPI';
import { useSnackbar } from '../../../context/SnackbarContext';
import ConcentrationNoteEditor from './ConcentrationNoteEditor';
import ConcentrationStatusDialog from './ConcentrationStatus';
import ConcentrationVersions from './ConcentrationVersions';

const ConcentrationEditor = ({ open, onClose, segment, company, study, forceReload }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const {data, loading, error} = useAPI('concentrations', {params:{segment:segment.name,company_id:company.id, study_id: study.id, year:2024}})
  const [concentrations, setConcentrations] = useState([]);
  const [noteOpen, setNoteOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [versionOpen, setVersionOpen] = useState(false);
  const [noteConcentration, setNoteConcentration] = useState(null);
  const [newConc, setNewConc] = useState([]);
  const [compTotal, setCompTotal] = useState(0)
  const [total, setTotal] = useState(100);
  const [isError, setIsError] = useState(false);
  const {openSnackbar} = useSnackbar();
  const Decimal = require('decimal.js')

  useEffect(() => {
    if (data) {
        console.log("fetched concentrations", data.data)
        const concList = data.data.map(item => ( {...item, concentration: new Decimal(item.concentration).times(100), revenue: new Decimal(item.revenue.toFixed(3))} ))
        setConcentrations(concList)
        const total = data.data.reduce((acc, obj) => acc+obj.revenue,0)
        setCompTotal(total);
    }
  },[data, company, segment])

  const getTotal = (segments) => {
    return segments.reduce((acc, seg) => acc + Number(seg.concentration), 0);
  };
  const openStatusDialog = (conc) => {
    setNoteConcentration(conc)
    setStatusOpen(true)
  }
  const openVersionDialog = (conc) => {
    setNoteConcentration(conc)
    setVersionOpen(true)
  }
  const handleVersionClose = () => {  
    setVersionOpen(false)
  }
  const handleStatusClose = () => {
    setStatusOpen(false)
  }
  const handleStatusSave = (conc, status) => {
    const updatedArray = newConc.map(item => 
      item.category === conc.category ? {...item, status: status} : item)
    setNewConc(updatedArray)
    setStatusOpen(false)
  }
  const openNote = (conc) => {
    setNoteConcentration(conc)
    setNoteOpen(true)
  }
  const handleNoteClose = () => { 
    setNoteOpen(false)
  }
  useEffect(() => {
    
    const totalRaw = getTotal(concentrations)
    const total = totalRaw.toFixed(2)
    setTotal(total)
    console.log("total", total)
    if (concentrations != [] && total !== "100.00") {
      setIsError(true)
    }
    else { setIsError(false) }
  },[concentrations])
  
  const handleTotalChange = (category, totalValue) => {
      const conc = (totalValue/compTotal)*100
      const updatedArray = concentrations.map(item => 
        item.category === category ? {...item, concentration: conc, revenue:totalValue} : item
      );
      setConcentrations(updatedArray)
  }
  const handleChange = (category, value) => {
    
    const updatedArray = concentrations.map(item => 
      item.category === category ? {...item, concentration: value, revenue: (value/100)*compTotal} : item
    );
    console.log("updated array", updatedArray)
    setConcentrations(updatedArray)

  }
  const saveNote = (note,category) => {
    const updatedArray = newConc.map(item => 
      item.category === category ? {...item, note: note} : item
    );
    setConcentrations(updatedArray)
  };

  const handleSave = async () => {
    setSubmitLoading(true)
    try {
      console.log("saving", concentrations)
      const response = await axiosInstance.put('concentrations', {concentrations:concentrations})
      openSnackbar('Concentrations Saved Successfully')
      onClose()
      setSubmitLoading(false)
      forceReload()

  } catch(error) {
      console.log(error)
      openSnackbar('Error saving Concentrations', 'error')
      onClose()
      setSubmitLoading(false)
  };
  }

  const handleCancel = () => {
    setNewConc([])
    onClose();
  };

  return (
    <Dialog open={open} 
        onClose={onClose}
        maxWidth='lg'
        fullWidth>
      <DialogTitle color='primary'>Adjust Market Concentration: <b> {company.name} </b></DialogTitle>
      <DialogContent>
        {loading && <Box sx={{width:1,marginTop:10, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
        {(!loading) && 
        <Grid container marginTop={3} spacing={3}>
          {isError && 
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">The concentrations do not add up to 100. You will not be able to submit this change until they do. Total: <b>{total}</b></Alert>
            </Grid>}
            {(compTotal>0) && 
            <Grid item xs={12}>
              <Typography variant='body1'> Total {compTotal}</Typography>
            </Grid>}
          {concentrations.map((conc) => (
            <Grid item xs={6} key={conc.category}>
              <Stack direction={'horizontal'} spacing={2} sx={{position:'relative'}}> 
                <TextField
                  label={conc.category}
                  type="number"
                  variant="outlined"
                  error = {isError}
                  sx={{width:.65}}
                  value={conc.concentration}
                  onChange={(e) => handleChange(conc.category, e.target.value)}
                  inputProps={{ min: 0, max: 100, step: 0.1}}
                  InputProps= {{ endAdornment: <InputAdornment position="end">%  </InputAdornment>
                  }}
                  
                  />
                  <Box sx={{width:0.1}}/>
                  <TextField 
                    value={conc.revenue}  
                    type="number"
                    variant="standard"
                    sx={{width:0.25, position:'absolute', bottom:0, right:0, fontSize:14, fontWeight:'bold'}} 
                    onChange={(e) => handleTotalChange(conc.category, e.target.value)}
                    InputProps= {{ startAdornment: <InputAdornment position="start"> $</InputAdornment>,
                      sx: {
                        fontStyle: 'italic'
                      }
                     }}
                    />
                
                  
              </Stack>
                
                <Button onClick={() => openNote(conc)}>Note</Button>
                <Button onClick={() => openVersionDialog(conc)}>Versions</Button>
                <Button onClick={() => openStatusDialog(conc)}>Status</Button>
                
          </Grid>
          ))}
        </Grid>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button variant="contained" disabled={isError} onClick={handleSave} color="primary">
          {submitLoading && <CircularProgress size={20} color='background' />}
          {(!submitLoading) && 
          'Save'}
        </Button>
      </DialogActions>
      {noteConcentration && 
      <>
      <ConcentrationNoteEditor open={noteOpen} onClose={handleNoteClose} concentration={noteConcentration} saveNote={saveNote} />
      <ConcentrationStatusDialog open={statusOpen} onClose={handleStatusClose} onSaveChanges={handleStatusSave} concentration={noteConcentration} />
      <ConcentrationVersions open={versionOpen} onClose={handleVersionClose} concentration={noteConcentration} />
      </>
}
    </Dialog>
  );
}; 

export default ConcentrationEditor;
