import React,{useState, useEffect} from 'react'
import useAPI from "../../../../hooks/useAPI"
import { DataGrid } from '@mui/x-data-grid';
import {Grid, Divider, Box, Tooltip, Button, CircularProgress, Typography} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import StatusChip from '../../../chips/StatusChip';
import formatCurrency from '../../../../utils/formatCurrency';
import ActionsButton from '../../../buttons/ActionsButton';
import TotalActions from '../../../company_study/totals/TotalActions';
import formatDecimal from '../../../../utils/formatDecimal';
import StatusSelect from './StatusSelect';
import StatsGrid from './StatsGrid';


const columnGrouping = [
    {
        groupId:"2023",
        children: [{field:'revenue_py'}, {field:'status_py'}, {field:'time_updated_py'}, {field:'actionsPY'}],
        headerAlign:'center',
        headerClassName: 'year-group'
    },
    {
        groupId:"2024",
        children: [{field:'revenue'}, {field:'status'}, {field:'time_updated'}, {field:'actionsCur'}],
        headerAlign:'center',
        headerClassName: 'year-group'
       },
    {
        groupId: 'YoY',
        children: [{field:'revenueYoY'}, {field:'revenueYoYPercent'}],
        headerAlign:'center',
        headerClassName: 'year-group'
    }
    ]
const filterRows = (rows, statusArray) => {
    if (statusArray.length>0){
        return (
            rows.filter(row => statusArray.includes(row.status))
        )
    }
    else {
        return (rows)
    }
}
const makeRows = (data) => {
  
    var totalCurrentYear = 0;
    var totalLastYear = 0;
    var totalGrowth =  0;
    var averageGrowth = 0;

    const rows = data.map((company) => {
        const total = company.most_recent_total;
        const totalPY = total.total_previous_year;
        const yoyRev = Number(total.revenue)-Number(totalPY.revenue);
        const yoyRevPercent = yoyRev/Number(totalPY.revenue);
        totalCurrentYear+= total.revenue;
        totalLastYear += Number(totalPY.revenue);
        averageGrowth += yoyRevPercent
        return (
            {
                ...company, 
                revenue_py: totalPY.revenue,
                status_py: totalPY.status,
                time_updated_py: totalPY.time_updated,
                revenue: total.revenue,
                status: total.status,
                time_updated: total.time_updated,
                revenueYoY: yoyRev,
                revenueYoYPercent:yoyRevPercent
            }
        )
    })
    return ({
            rows:rows.sort((a,b) => b.revenue-a.revenue),
            stats: {averageGrowth:(averageGrowth)/data.length, totalCurrentYear:totalCurrentYear, totalLastYear:totalLastYear, totalGrowth: (totalCurrentYear-totalLastYear)/totalLastYear}
    })
}


export default function CompanyAnalysis({study}) {
    const [reload, setReload] = useState(false)
    const {data, error, loading} = useAPI('v1/studies/'+study.id+'/companies',{} ,"failed to load companies", [reload]);
    const [stats, setStats] = useState({});
    const [rows, setRows] = useState(null);
    const navigate  = useNavigate();
    const [statuses, setStatuses] = useState([]);
    const [selectedTotal, setSelectedTotal] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
  
    const handleMenuOpen = (event, total) => {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
      setSelectedTotal(total)
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
      };

    const forceReload = () => {
        setReload(!reload)
    }
    const columns = [
  
        {field: 'name', headerName: 'Company', width: 300, 
            renderCell: (params) => {
                return <Button 
                        onClick={() => navigate('/study-detail/'+study.name + '/'+params.value, {state:{company:params.row}})}>
                            {params.value}
                        </Button>}},
        {field: 'revenue_py', type:'number', headerName: 'Revenue', width: 110, renderCell: (params) => <Tooltip title={<React.Fragment><Typography variant={'body2'}>Last Edited: {params.row.time_updated_py} </Typography><Typography variant={'body2'}> Last Edited By: Test User</Typography> </React.Fragment>}><span>{formatCurrency(params.value)} </span> </Tooltip> },
        {field: 'status_py', headerName: 'Status', width: 150, renderCell: (params) => <StatusChip status={params.value}/>},
        {field:'actionsPY', type:'actions', width:40, renderCell: (params) => 
            <>
            <ActionsButton buttonType={'slim'} handleClick={(event) => handleMenuOpen(event, params.row.most_recent_total.total_previous_year)}/>
            <TotalActions
                    key={params.row.most_recent_total.total_previous_year.id}
                    anchorEl={anchorEl}
                    open={menuOpen && selectedTotal?.id===params.row.most_recent_total.total_previous_year.id}
                    onClose={handleMenuClose}
                    company={params.row}
                    total={params.row.most_recent_total.total_previous_year}
                    study={study}
                    forceReload={forceReload}
                />
            <Divider orientation='vertical'/> 
            </>
         },
        {
            field: 'revenue', 
            type:'number', 
            headerName: 'Revenue', 
            width: 110, 
            renderCell: (params) => 
            <Tooltip 
                title={
                <React.Fragment>
                    <Typography variant={'body2'}>Last Edited: {params.row.time_updated} </Typography>
                    <Typography variant={'body2'}> Last Edited By: Test User</Typography> 
                </React.Fragment>}>
                    <span>{formatCurrency(params.value)} </span> {params.row.most_recent_total.acquisition && <StarIcon fontSize={'9pt'} color={'primary'}/>} </Tooltip>},
        {field: 'status', headerName: 'Status', width: 150, renderCell: (params) => <StatusChip status={params.value}/>},
        {field: 'actionsCur', type: 'actions', width:40, renderCell: (params)=> 
            <>
                <ActionsButton buttonType={'slim'}  handleClick={(event) => handleMenuOpen(event, params.row.most_recent_total)}/>
                <TotalActions
                    key={params.row.most_recent_total.id}
                    anchorEl={anchorEl}
                    open={menuOpen && selectedTotal?.id===params.row.most_recent_total.id}
                    onClose={handleMenuClose}
                    company={params.row}
                    total={params.row.most_recent_total}
                    study={study}
                    forceReload={forceReload}
                />
                <Divider orientation='vertical'/>
          </>
        },
        {field: 'revenueYoY', type:'number', headerName: 'Growth', width: 80, valueFormatter: (value) => formatCurrency(value)},
        {field: 'revenueYoYPercent', type:'number', headerName: '(%)', width: 80, valueFormatter: (value) => formatDecimal(value)}
      ];
      
    useEffect(() => {
        if (data) {
            const rows = makeRows(data.data)
            setRows(rows.rows)
            setStats(rows.stats)
        }
    },[data])

    const filteredRows = filterRows(rows, statuses)
    return (
        <Grid  container>
            <Grid item xs={3}>
                <StatusSelect statuses={statuses} setStatuses={setStatuses}/>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={7}>
                <StatsGrid stats={stats}/>
            </Grid>
            <Grid  item xs={12} sx={{height:800,overflow: 'auto' }}>
                {loading && 
                <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
                {rows && 
                <DataGrid 
                    rows={filteredRows} 
                    columns={columns}
                    columnGroupingModel={columnGrouping}
                    
                    sx={{ '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: '#f5f5f5', // Light gray for odd rows
                        },
                        '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#ffffff', // White for even rows
                        },'& .year-group': {color:'#1f409c', fontSize:'14pt', fontWeight:'bold'}}}
                        />
                }
            </Grid>
        </Grid>
    )
}