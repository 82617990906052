import {useState} from 'react'
import {Grid} from '@mui/material'
import SegmentOptions from '../autocomplete/SegmentOptions'
import SegmentTable from './SegmentsTable'


export default function Segmentation({study}) {
    const [selectedSegment, setSelectedSegment] = useState({name: 'Industry', label: 'Industry'});


    return (
        <Grid justifyContent={'center'} alignContent={'center'} container spacing={2}>
            <Grid item xs={6}> 
                <SegmentOptions segment={selectedSegment} setSegment={setSelectedSegment} study={study}/>
            </Grid>
            <Grid item xs={8}>
                <SegmentTable segment={selectedSegment} study={study}/>
            </Grid>
        </Grid>
    )
}