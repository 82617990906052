import {useState, useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid'
import {CircularProgress, Box, Typography} from '@mui/material'
import useAPI from '../../hooks/useAPI'
import ActionsButton from '../buttons/ActionsButton'
import SegmentActions from './SegmentActions'
import formatCurrency from '../../utils/formatCurrency'
import formatDecimal from '../../utils/formatDecimal'

const splitData = (data, group_by) => {
    const groups = [...new Set(data.map((d) => d[group_by]))]
    const yearOne = data.filter((d) => d.year === 2023)
    const yearTwo = data.filter((d) => d.year === 2024)

    const mapped = groups.map((groupItem) =>  {
        const first = yearOne.filter((item) => item[group_by]===groupItem)[0];
        const second = yearTwo.filter((item) => item[group_by]===groupItem)[0];
            return ({revenue23: first.total,
                        share23: first.total,
                        revenue24: second.total,
                        share24: second.market_share,
                        revenueYoY: ((second.total-first.total)/first.total),
                        id: groupItem,
                        name: groupItem,
                        other: null
                    }
                    )})
                
    return (mapped)
                }


export default function SegmentTable({study, segment}) {
    const {data, error, loading} = useAPI('concentrations', {params:{study_id:study.id, segment:segment.name, group_by:'year, category', summarize:'true'}},'Failed to load segment data', [segment]);
    const [rows, setRows] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
  
    const handleMenuOpen = (event, category) => {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
      setSelectedCategory(category)
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      setMenuOpen(false);
    };

    useEffect(() => {
        if (data) {
            const rows = splitData(data.data, 'category')
            console.log(rows)
            setRows(rows)
        }
    }, [data])

    const columns = [
        {field:'name',
            type:'string', 
            headerName:'Category', 
            flex:2,
            renderCell: (params) => <Box> <Typography color='primary' fontWeight='bold'>{params.value}</Typography></Box>
           },
        {field:'revenue23',
            type:'number', 
            headerName:'2023', 
            flex:1,
            valueFormatter: (value) => formatCurrency(value)    
           },
        {field:'revenue24',
            type:'number', 
            headerName:'2024', 
            flex:1,
            valueFormatter: (value) => formatCurrency(value)  
            },
        {field:'revenueYoY',
                type:'number', 
                headerName:'Year over Year', 
                flex:1, 
                valueFormatter: (value) => formatDecimal(value)  
                },
        {field:'actions', type:'actions', renderCell: (params) => 
            <>
            <ActionsButton buttonType={'slim'} handleClick={(event) => {handleMenuOpen(event, params.row)}}/>
            <SegmentActions 
                anchorEl={anchorEl} 
                open={menuOpen && selectedCategory?selectedCategory.name===params.row.name:null} 
                onClose={handleMenuClose} 
                study={study} 
                category={params.row.name}/>
            </>
        }
        ]
    return (
        <>
            {loading && <Box sx={{width:1, display:'flex', justifyContent:'center', alignItems:'center'}}> <CircularProgress/> </Box>}
            {error && <Box> <Typography variant='h2' color='error'> Failed to load data. </Typography></Box>}
            {rows && 
            <DataGrid 
            sx={{
                '& .MuiDataGrid-row:nth-of-type(odd)': {
                  backgroundColor: '#f5f5f5', // Light gray for odd rows
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: '#ffffff', // White for even rows
                },
                '& .MuiDataGrid-columnHeaders': {
                    fontWeight: 'bold',
                  },
            }}
            rows={rows}
            columns={columns}
            hideFooter
            />
            }
            
        </>
    )
}