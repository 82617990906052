import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import {useSnackbar} from '../../context/SnackbarContext';
import axiosInstance from '../../axiosConfig';

import {Box,
        Tabs,
        Tab,
        Grid,
        CircularProgress,
        Typography
         } from '@mui/material'

import CompaniesTable from '../study/CompaniesTable';
import StudyStatus from '../study/StudyStatus';
import HistoryChart from '../charts/HistoryChart';
import ModellingStepper from '../study/modelling/ModellingStepper';
import Segmentation from '../study/Segmentation';


const TabPanel = ({value, study}) => {
    switch(value) {
      case 'one':
        return <StudyStatus study={study}/>
      case 'two':
        return <HistoryChart study={study}/>
      case 'three':
        return <CompaniesTable study={study}/>
      case 'four':
        return <ModellingStepper study={study}/>
      case 'five':
        return <div>Differentiators</div>
      case 'six':
        return <Segmentation study={study}/>
      default:
        return <div>Overview</div>
    }
  }


export default function StudyPage() {
    const {studyName} = useParams();
    const [loading, setLoading] = useState(false);
    const [study, setStudy] = useState(null)
    const [value, setValue] = useState('one');
    const { openSnackbar } = useSnackbar(); 


    useEffect(() => {
        const fetchStudy = async () => {
            try {
                setLoading(true)
                const response = await axiosInstance.get('/studies/search',{params: {"name":studyName}})
                setStudy(response.data.data)
                setLoading(false)
            } catch (error){
                console.log("error fetching study from url.", error )
                openSnackbar(error.message, 'error')
                setLoading(false)
        }}
        if (!study) {
           fetchStudy()
        }
            
    },[])
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    
    if (!study) {
      
      return (
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>
          </Grid>
        </Grid>

      )
    }
    else {
       
      return (
        <>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            
              <Typography variant='h4' color='primary' sx={{fontWeight:'bold'}}> {study.name} </Typography>
    
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', justifyContent: 'center', alignItems:'center', display:'flex' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
              >
                <Tab value="one" label="Status"/>
                <Tab value="two" label="History"/>
                <Tab value="three" label="Companies" />
                <Tab value="four" label="Modelling" />
                <Tab value="five" label="Study Text" />
                <Tab value="six" label="Segmentation" />
              </Tabs>
    
            </Box>
          </Grid>
          <Grid item xs={12}>
          {loading &&  <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
          {(!loading) && study && <TabPanel value={value} study={study}/>}
          </Grid>
        </Grid> 
           
        </> )
    }
  }
    