import { useState } from "react"
import { Dialog, Grid, ToggleButton, ToggleButtonGroup, IconButton, DialogTitle, DialogContent, Typography } from "@mui/material"
import Financials from './Financials'
import CloseIcon from '@mui/icons-material/Close'

export default function FinancialsModal({open, onClose, category}) {
    const [type, setType] = useState('revenue');

    const handleChange = (event, value) => {
        setType(value)
    }
    return (
        
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant={'h6'} fontWeight='bold' color='primary' textAlign={'center'}> {category} Revenue</Typography>
            </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            <DialogContent>
                <Grid alignItems={'center'} justifyContent={'center'} container spacing={3}>
                    <Grid item xs={4}/>
                        
                    <Grid item xs={4}>
                        <ToggleButtonGroup
                        color="primary"
                        value={type}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        >
                            <ToggleButton value="revenue">Revenue</ToggleButton>
                            <ToggleButton value="capex">Capex</ToggleButton>
                            <ToggleButton value="assets">Assets</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={12}>
                        <Financials type={type} category={category}/>
                    </Grid>
                </Grid>       
            </DialogContent>
        </Dialog>
    )
}