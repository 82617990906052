
import {useState, useEffect} from 'react';
import { DataGrid } from "@mui/x-data-grid"
import { Button, Box, CircularProgress, Typography, Container, Fab} from "@mui/material"
import { useNavigate } from "react-router-dom"
import useAPI from "../../hooks/useAPI"
import AddIcon from '@mui/icons-material/Add';
import AddCompanyModal from './AddCompanyModal';

import ActionsButton from '../buttons/ActionsButton'
import CompanyActions from '../company_study/company_actions/Menu'
import StatusChip from '../chips/StatusChip';
import formatCurrency from '../../utils/formatCurrency';

const extractTotals = (totals, attr) => { 
    if (totals.length > 0) {
        return totals[0][attr]
    }
    else {
        return "N/A"
    }}

const makeRows = (companies) => {
    const rows = [] 
    companies.forEach((company) => rows.push({ 'id': company.id, 
      'name':company.name, 
      'total_rev':Number(extractTotals(company.totals, 'revenue')),
      'total_status': extractTotals(company.totals, 'status'),  
      'time_updated': extractTotals(company.totals, 'time_updated')}))
    return (rows)
}

export default function CompaniesTable({study}) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
  const [companies, setCompanies] = useState([]);
  const {data, error, loading} = useAPI('/studies/'+study.id+'/companies');

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null)

  const handleMenuOpen = (event, company) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setSelectedCompany(company)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  useEffect(() => {
    if (data) {
      data.data.sort((a, b) => (a.totals[0].revenue < b.totals[0].revenue) ? 1 : -1)
      setCompanies(data.data)
    }
  },[data]
)
  const handleModalClose = () => {
    setOpenModal(false)
  }
  const columns = [
  
    {field: 'name', headerName: 'Company', flex:5, renderCell: (params) => {return <Button onClick={() => navigate('/study-detail/'+study.name + '/'+params.value, {state:{company:params.row}})}>{params.value}</Button>}},
    {field: 'total_rev', type:'number', headerName: 'Total Revenue', flex:1, valueFormatter: (value) => formatCurrency(value)},
    {field: 'total_status', headerName: 'Revenue Status', flex:1, renderCell: (params) => <StatusChip status={params.value}/>},
    {field: 'time_updated', headerName: 'Time Updated', flex:2, valueGetter:(value)=> Date(value) },
    {field: 'actions', type:'actions', renderCell: (params) => 
        <>
        <ActionsButton buttonType={'slim'} handleClick={(event) => {handleMenuOpen(event, params.row)}}/>
        <CompanyActions onClose={handleMenuClose} company={params.row} study={study} anchorEl={anchorEl} open={menuOpen && selectedCompany?.name===params.row.name}/>
        </>
    }
  ];
  
  return (
    <Container width={1} sx={{height:600, marginTop:5}}>
      {loading && <Box sx={{display:'flex', justifyContent:'center'}}><CircularProgress/></Box>}
      {(!loading) && error && <Box sx={{display:'flex', justifyContent:'center'}}><Typography variant='h5'>Error fetching companies</Typography></Box>}
      {(!loading) && (!error) &&
      <Box height={1}>
        <DataGrid
          rows={makeRows(companies)}
          columns={columns}
          sx ={{'& .MuiDataGrid-row:nth-of-type(odd)': {
                backgroundColor: '#f5f5f5', // Light gray for odd rows
              },
              '& .MuiDataGrid-row:nth-of-type(even)': {
                backgroundColor: '#ffffff', // White for even rows
              },
              '& .MuiDataGrid-columnHeaders': {
              fontWeight: 'bold',
            },
                      }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
        />
      </Box>}
      <Fab sx={{position:'absolute', bottom:50, right:100}}
          color="primary"
          aria-label="add"
          onClick={() => {setOpenModal(true)}}>
        <AddIcon />
      </Fab>
      <AddCompanyModal open={openModal} onClose={handleModalClose} study={study}/>
    </Container>
  );
}