
import React, {useState, useEffect} from 'react';
import { CircularProgress, Container, Grid, Paper, Stack, Typography, Box } from '@mui/material';

import PieChart from '../charts/basecharts/PieChart';

import useAPI from '../../hooks/useAPI';


function createDataset(records, field) {

    return (
            {labels: records.map(record => record.name), 
            datasets: [
                {
                    label: field,
                    data: records.map(record => record[field]),
                    backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc', '#f6c23e'],
                    hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf', '#d6b45e'],
                    hoverBorderColor: "rgba(234, 236, 244, 1)",
                  }
                ]
            }
            )
}
const statuses = ['override', 'modelled', 'supplier_input', 'revised']


function extractTotals(totals) {
    return statuses.map(status => {
        const filtered = totals.filter(total => total.status === status)
        console.log("filtered", filtered)
        return ({name: status,
                count:filtered.length,
                sum: filtered.reduce((acc, total) => acc + total.revenue, 0)
            })
    })
}
function flattenTotals(companies) {
    console.log("flattening totals", companies)
    return companies.flatMap(company => {
      return company.totals.filter(total => total.year==2024).map(total => ({
        arc_name: company.arc_name,
        id: company.id,
        name: company.name,
        ticker: company.ticker,
        ...total
      }));
    })

    };
  
function countByStatus(totals, status) {
    if (totals != []) {
        return totals.filter(total => total.status === status).length
    }
}


export default function StudyStatus({study}) {
    const {data, error, loading} = useAPI('studies/'+ study.id+'/companies')
    const [totals, setTotals] = useState([]);
    const [extracted, setExtracted] = useState([]);
    console.log("study status component.", study)
    
    useEffect(() => {
        if (data) {
            console.log("data from study status component", data.data)
            var flattened = flattenTotals(data.data)
            setTotals(flattened)
            setExtracted(extractTotals(flattened))
        }

    }, [data])
    return (
        <Container>
                {loading && <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
                { error && (!loading) && <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><Typography> Error loading data </Typography></Box>}
                {(!loading) && 
                <Grid container spacing={2} style={{ padding: '20px'}} justifyContent={"center"} alignItems={"center"}>
                    <Grid item sm={2}>
                        <Paper elevation={4} margin={'1vh'} sx={{padding:'2vh', backgroundColor:"#dfebf0", border: '1px solid #1f409c'}}>
                            <Stack>
                                <Typography variant='h5'> {countByStatus(totals, 'override')} </Typography>
                                <Typography variant='caption'> Override</Typography>
                            </Stack>    
                        </Paper>
                    </Grid>
                    <Grid item sm={2}>
                        <Paper elevation={4} sx={{padding:'2vh', backgroundColor:"#dfebf0", border: '1px solid #1f409c'}}>
                            <Stack>
                            <Typography variant='h5'> {countByStatus(totals, 'modelled')} </Typography>
                            <Typography variant='caption'> Modelled</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item  sm={2}>
                        <Paper elevation={4} sx={{padding:'2vh',  backgroundColor:"#dfebf0", border: '1px solid #1f409c'}}>
                            <Stack>
                            <Typography variant='h5'> {countByStatus(totals, 'supplier_input')} </Typography>
                            <Typography variant='caption'> Supplier Input</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item sm={2}>
                        <Paper elevation={4} sx={{padding:'2vh',  backgroundColor:"#dfebf0", border: '1px solid #1f409c'}}>
                            <Stack>
                            <Typography variant='h5'> {countByStatus(totals, 'revised')} </Typography>
                            <Typography variant='caption'> revised</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={5}>
                        <Box width={1}>
                            <Typography variant='h5'> Companies by Status</Typography>
                        </Box>
                        <Box marginTop= {5} marginBottom={5} width={1}>
                            <PieChart id={'count'} chartData = {createDataset(extracted,'count')}/>
                        </Box>
                    </Grid> 
                    <Grid item xs={5}>
                            <Box width={1}>
                                <Typography variant='h5'> Market by Status</Typography>
                            </Box>
                        <Box marginTop= {5} marginBottom={5} width={1}>
                            <PieChart id={'market'} chartData = {createDataset(extracted,'sum')}/>
                        </Box>
                    </Grid> 
                </Grid>}
        </Container>)
}
