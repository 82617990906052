import {useState, useEffect} from 'react'
import {Container } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import useAPI from '../../../hooks/useAPI'
import { X } from '@mui/icons-material'

const makeRows = (data, growthMatrix) => {
    return (data.map((obj) => {
        const mapper = growthMatrix[obj.category]
        return (
            {
            id: obj.category,
            name: obj.category,
            revenue24: obj.total,
            revenueOne: obj.total * (1+ mapper.one),
            revenueTwo: obj.total * (1+mapper.two),
            revenueThree: obj.total * (1+mapper.three),
            revenueFour: obj.total * (1+mapper.four),
            revenueFive: obj.total * (1+mapper.five)
            }
        )
    })
)}
export default function ForecastTotal({company, study}) {
    const [matrix, setMatrix] = useState({'Asia': {one: 0, two:0, three:0, four:0, five:0}, 'Latin America': {one: 0, two:0, three:0, four:0, five:0}, 'North America': {one: 0, two:0, three:0, four:0, five:0}, 'EMEA': {one: 0, two:0, three:0, four:0, five:0}});
    const {data, error, loading} = useAPI('concentrations', {params:{study_id:study.id, year:2024, segment:'World Region', summarize:'true'}});
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (data) {
            const rows = makeRows(data.data, matrix);
            console.log("generated rows", rows)
            setRows(rows)
        }
    },[data])

    const columns = [
        {field:'name',
            type:'string', 
            headerName:'Category', 
            width:100
           },
        {field:'revenue24',
            type:'number', 
            headerName:'2024', 
            width:100,
            valueFormatter: (value) => {
               return `$ ${value.toFixed(2)}`}
           },
        {field:'revenueOne',
            type:'number', 
            headerName:'2025', 
            width:100,
            valueFormatter: (value) => {
               return `$ ${value.toFixed(2)}`}
           },
        {field:'revenueTwo',
            type:'number', 
            headerName:'2026', 
            width:100,
            valueFormatter: (value) => {
               return `$ ${value.toFixed(2)}`}
           },
        {field:'revenueThree',
            type:'number', 
            headerName:'2027', 
            width:100,
            valueFormatter: (value) => {
               return `$ ${value.toFixed(2)}`}
           },
        {field:'revenueFour',
            type:'number', 
            headerName:'2028', 
            width:100,
            valueFormatter: (value) => {
               return `$ ${value.toFixed(2)}`}
           },
        {field:'revenueFive',
            type:'number', 
            headerName:'2029', 
            width:100,
            valueFormatter: (value) => {
               return `$ ${value.toFixed(2)}`}
           },
    ]
    return (
        <Container>
            <DataGrid
                rows={rows}
                columns={columns}
                />
        </Container>
    )
}