import * as React from 'react';
import {Box,
        Container,
        Stepper,
        Step,
        StepButton,
        Button,
        Typography } from '@mui/material';
import CompanyAnalysis from './company_analysis/CompanyAnalysis';
import MarketAnalysis from './market_analysis/MarketAnalysis';
import ForecastTotal from '../forecasts/ForecastTotal';

const steps = ['Company Analysis', 'Market Analysis', 'Forecast Total', 'Forecast Categories'];

function stepComponent(step, study) {
    switch(step) {
        case 1:
            return <CompanyAnalysis study={study}/>
        case 2:
            return <MarketAnalysis study={study}/>
        case 3:
            return <ForecastTotal study={study}/>
        case 4:
            return <div> Forecast Categories</div>
        default:
            return <div> Something went wrong.</div>
    } 
}

export default function ModellingStepper({study}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Container> 
    <Box sx={{ width: '100%', marginTop:'10vh'}}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              
            </Box>
            <Box sx={{ mt: 2, mb: 1, py: 1 }}>
             {stepComponent(activeStep+1, study)}
            </Box>
          </React.Fragment>
        )}
      </div>
      
    </Box>
    </Container>
  );
}
