import React, { useState } from 'react';
import {Popper, Paper, MenuItem, ClickAwayListener} from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CategoryCompanies from './CategoryCompanies';
import FinancialsModal from '../industry/FinancialsModal';

const ActionsMenu = ({ anchorEl, open, onClose, category, study }) => {
    const [openAll, setOpenAll] = useState(false);
    const [openFinancials, setOpenFinancials] = useState();

    const handleClose = () => {
        setOpenAll(false)
        setOpenFinancials(false)
      }
    return (
<>
  <Popper open={open} anchorEl={anchorEl} disablePortal placement={'left-start'}>
    <ClickAwayListener onClickAway={onClose}>
      <Paper>
        <MenuItem onClick={setOpenAll}> <VisibilityIcon sx={{marginRight:2}}/> View Market</MenuItem>
        <MenuItem onClick={setOpenFinancials}> <QueryStatsIcon sx={{marginRight:2}}/> Financials</MenuItem>
      </Paper>
    </ClickAwayListener>
  </Popper>
  {openAll && 
  <CategoryCompanies study={study} category={category} open={openAll} onClose={handleClose}/>
  }
  {openFinancials &&
  <FinancialsModal open={openFinancials} onClose={handleClose} category={category}/>
  }
  {}
  </>
  )
};

export default ActionsMenu;
