import {useState} from 'react'
import {Dialog, DialogActions,IconButton, TextField, Grid, InputAdornment, Button, DialogContent, DialogTitle} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CompanyOptions from '../autocomplete/CompanyOptions'
import axiosInstance from '../../axiosConfig'

export default function AddCompanyModal({open, onClose, study}) {

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [total, setTotal] = useState(null);


    const handleSubmit = () => {
        const data = {"company_id": selectedCompany.id, "total": total}
        console.log(data)
    }
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>Add a Company to {study.name}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={7}>
                        <CompanyOptions company={selectedCompany} setCompany={setSelectedCompany}/>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            variant={'outlined'}
                            onChange={(event, newValue) => {
                                setTotal(newValue);
                            }}
                            value={total}
                            label="Total"
                            fullWidth
                            InputProps= {{ startAdornment: <InputAdornment position="start"> $</InputAdornment> }}
                        />
                    </Grid>
                </Grid>
                

            </DialogContent>
            <DialogActions>
                <Button> Submit</Button>
            </DialogActions>
        </Dialog>
    )
}



