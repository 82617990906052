
import React from 'react';
import { Dialog,
        DialogTitle, 
        DialogContent, 
        IconButton,
        Typography
         } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import CompanyHistory from '../../charts/CompanyHistory';


const CompanyCategoryHistory = ({ open, onClose, company, study, segment, category }) => {


    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
            <DialogTitle><Typography variant='h5' textAlign={'center'} color='primary'> {company.name} Historical Revenues in {study.name} for {category} </Typography></DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
          <CloseIcon />
        </IconButton>
           
            <DialogContent>
                <CompanyHistory company={company} study={study} segment={segment} category={category}/>
            </DialogContent>
            
          
    
        </Dialog>
    );
};

export default CompanyCategoryHistory;