
import {useState, useEffect} from 'react';
import {Box, CircularProgress, Container} from '@mui/material';
import { Line } from "react-chartjs-2";
import useAPI from "../../hooks/useAPI";
import  numberWithCommas  from "../../utils/formatNumber";

export default function HistoryChart({study}) {
    const {data, error, loading} = useAPI('/studies/'+study.id+'/history');
    const [dataset, setDataset] = useState([]);

    useEffect(() => {
        if (data) {
            console.log("response", data)
            setDataset(data)
        }   
    }   
    ,[data])

    const  years = dataset.map(entry => entry.year)
    const marketSize = dataset.map(entry => entry.market_size)
    const config = {
    type: 'line',
    data: {
        labels: years,
            datasets: [{
            label: "Historical",
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            //backgroundColor: backgroundColors,
            borderColor: "rgba(78, 115, 223, 1)",
            pointRadius: 3,
            pointBackgroundColor: "rgba(78, 115, 223, 1)",
            pointBorderColor: "rgba(78, 115, 223, 1)",
            pointHoverRadius: 3,
            pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
            pointHoverBorderColor: "rgba(78, 115, 223, 1)",
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: marketSize
            //fill: false
            },
        {
            label: "Forecast",
            lineTension: 0.3,
            backgroundColor: "rgba(159, 187, 201, 0.2)",
            //backgroundColor: backgroundColors,
            //borderColor: "rgba(78, 115, 223, 1)",
            borderWidth: 0,
            data: dataset.map(entry=> { if  (entry.year> 2023) {return 0;} else  { return null; };}),
            fill: 0,
            radius: 0
            }],
    },
    options: {
        maintainAspectRatio: false,
        layout: {
        padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
        }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                callback: function(value, index, values) {
                    return '$' + numberWithCommas(value) + ' mil.';
                    }
                }
            },
        xAxes: [{
            time: {
            unit: 'date'
            },
            gridLines: {
            display: false,
            drawBorder: false
            },
            ticks: {
            maxTicksLimit: 7
            }
        }],
        yAxes: [{
            ticks: {
            maxTicksLimit: 5,
            padding: 10,
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
                return '$' + numberWithCommas(value);
            }
            },
            gridLines: {
            color: "rgb(234, 236, 244)",
            zeroLineColor: "rgb(234, 236, 244)",
            drawBorder: false,
            borderDash: [2],
            zeroLineBorderDash: [2]
            }
        }],
        },
        legend: {
        display: true,
        position: 'right'
        },
        tooltips: {
        backgroundColor: "rgb(255,255,255)",
        bodyFontColor: "#858796",
        titleMarginBottom: 10,
        titleFontColor: '#6e707e',
        titleFontSize: 14,
        borderColor: '#dddfeb',
        borderWidth: 1,
        xPadding: 15,
        yPadding: 15,
        displayColors: false,
        intersect: false,
        mode: 'index',
        caretPadding: 10,
        callbacks: {
            label: function(tooltipItem, chart) {
            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
            return datasetLabel + ': $' + numberWithCommas(tooltipItem.yLabel);
            }
        }
        }
    }
    };
    return (
        <Container width={1} sx={{height:600}}>
            {loading &&  <Box sx={{ display: 'flex', width:1, justifyContent:'center' }}><CircularProgress/></Box>}
            {!loading && error && <div>Error loading data</div>}
            {!loading && !error &&
            <Line data={config.data} options={config.options} />}
        </Container>
    );
}